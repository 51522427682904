import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import GoogleButton from './GoogleSignUp';
import { theme } from '../assets/styles/main_theme';
import {
  Typography,
  TextField,
  makeStyles,
  MuiThemeProvider,
  Button,
} from '@material-ui/core';
import { MainGrid } from '../components/ui_elements';
import '../assets/styles/App.css';
import logo from '../assets/images/UpLift_Logo.svg';
import { useAnalyticsEvent } from '../controllers/misc_custom_hooks';
import useLogIn from '../controllers/useLogIn';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em',
  },
  textfield: {
    marginTop: '1em',
    color: 'black',
  },
  input: {
    color: '#272727',
  },
  inputLabel: {
    color: '#9c9c9c',
  },
});

interface LoginProps {
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstTime: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function RenderLogin(props: LoginProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [error, setError] = useState(false);
  const handleLogin = useLogIn;

  const query = window.location.search;
  const params = new URLSearchParams(query);
  const passedId = params.get('id');

  if (passedId) {
    props.setFirstTime(true);
  }

  useEffect(() => {
    if (passedId) {
      console.log(`Passed Id present, value is: ${passedId}`);
      (async (id: string) => {
        const response = await fetch(
          'https://api.uplift.app/uplift/retrieve_login',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: id,
            }),
          }
        );
        const loginRequest = (await response.json()) as {
          email: string;
          password: string;
        };
        console.log(`Credentials returned, email is: ${loginRequest.email}`);

        (await handleLogin(loginRequest, dispatch))
          ? props.setLoggedIn(true)
          : setError(true);
      })(passedId);

      // let loginRequest = {
      //   email: 'not yet',
      //   password: 'not yet',
      // };
      // (async () => {import { stringify } from 'uuid';
      //   loginRequest = await requestCredentials(passedId);
      //   console.log(`${JSON.stringify(loginRequest)}`);
      // })();

      // (async () => {
      //   (await handleLogin(loginRequest, dispatch))
      //     ? props.setLoggedIn(true)
      //     : setError(true);
      // })();
    }
  });

  const handleSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const loginRequest = {
      email: data.get('email') as string,
      password: data.get('password') as string,
    };
    (await handleLogin(loginRequest, dispatch))
      ? props.setLoggedIn(true)
      : setError(true);
  };

  useAnalyticsEvent('Main_login_loaded');

  return (
    <>
      <MainGrid noBackground={true}>
        <img src={logo} alt='' width={80} height={68}></img>
        <Typography variant='h5' style={{ marginTop: '2em' }}>
          Please Sign In
        </Typography>
        {error && (
          <Typography style={{ color: 'red' }}>
            Invalid email or password; please try again. Email us at
            support@uplift.app if you need help.
          </Typography>
        )}
        <form
          className={classes.form}
          onSubmit={handleSubmit}
          autoComplete='off'
        >
          <TextField
            variant='filled'
            className={classes.textfield}
            name='email'
            required
            id='email'
            type='email'
            label='Email'
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              className: classes.inputLabel,
            }}
          />
          <TextField
            variant='filled'
            className={classes.textfield}
            name='password'
            required
            id='password'
            label='Password'
            type='password'
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              className: classes.inputLabel,
            }}
          />
          <MuiThemeProvider theme={theme}>
            <Button
              type='submit'
              variant='contained'
              size='large'
              style={{
                width: 320,
                // textTransform: 'none',
                marginTop: '2rem',
                backgroundColor: `${theme.palette.primary.main}`,
                color: 'white',
              }}
            >
              Log In with email
            </Button>
          </MuiThemeProvider>
        </form>
      </MainGrid>
      {/* <Typography style={{ color: 'white', marginBottom: '.7em' }}>
        OR
      </Typography> */}
      {/* <AppleButton />  Don't remove - we'll want this later.*/}
      {/* <GoogleButton setUserEmail={props.setUserEmail} /> */}
    </>
  );
}
