import React, { Suspense, useEffect, useState, lazy } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './assets/styles/main_theme';
import Login from './views/Login';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
// import { StoredCredentials } from './assets/types';
import useLogIn from './controllers/useLogIn';

const Questionnaire = lazy(() => import('./views/Questionnaire'));
const Router = lazy(() => import('./Router'));
const StudySignup = lazy(() => import('./views/StudySignup'));

const query = window.location.search;
const params = new URLSearchParams(query);
const inStudy = params.get('study');

function App() {
  ReactGA.initialize('UA-97118261-2');
  // ! Make sure the default is set to `false` to enable login.
  const [loggedIn, setLoggedIn] = useState(false);
  const [doneQuestions, setDoneQuestions] = useState(false);
  const [firstTime, setFirstTime] = useState<boolean>(false);
  // const [
  //   pwaInstallEvent,
  //   storePWAInstallEvent,
  // ] = useState<BeforeInstallPromptEvent>();

  const dispatch = useDispatch();

  const handleLogin = useLogIn;

  useEffect(() => {
    console.log(
      'Production version 1.34 - Rolling back PWA, study-read\nDev version 1.36 - Connecting to onboard'
    );
    ReactGA.pageview(window.location.pathname + window.location.search);
    // const handleBeforeInstallPrompt = (e: Event) => {
    //   console.log('before install prompt event received');
    //   e.preventDefault();
    //   if (!localStorage.getItem('pwaOutcome')) {
    //     storePWAInstallEvent(e as BeforeInstallPromptEvent);
    //     console.log('Install prompt event saved.');
    //   }
    // };
    // const handleAppInstalledEvent = (e: Event) => {
    //   e.preventDefault();
    //   localStorage.setItem('pwaOutcome', 'accepted');
    // };
    // window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    // window.addEventListener('appinstalled', handleAppInstalledEvent);
    const user = localStorage.getItem('user');
    if (user) {
      console.log('Found existing user, signing in...');
      const token = JSON.parse(user).token as string;

      // const logInRequest = {
      //   email: userData.email,
      //   password: userData.password,
      // };
      // let logInResponse: boolean;
      (async () => {
        if (token) {
          (async (id: string) => {
            const response = await fetch(
              'https://api.uplift.app/uplift/retrieve_login',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  token: id,
                }),
              }
            );
            const loginRequest = (await response.json()) as {
              email: string;
              password: string;
            };
            console.log(
              `Credentials returned, email is: ${loginRequest.email}`
            );

            (await handleLogin(loginRequest, dispatch))
              ? setLoggedIn(true)
              : console.log('Found existing user object but could not sign in');
          })(token);
          // logInResponse = await handleLogin(logInRequest, dispatch);
          // logInResponse
          //   ? setLoggedIn(true)
          //   : console.log('Found existing user object but could not sign in');
        }
      })();
    }
    // return () => {
    //   console.log('removed event listeners');
    //   window.removeEventListener(
    //     'beforeinstallprompt',
    //     handleBeforeInstallPrompt
    //   );
    //   window.removeEventListener('appinstalled', handleAppInstalledEvent);
    // };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const renderLogin = () => {
    if (loggedIn) {
      if (!doneQuestions && inStudy) {
        return <Questionnaire switch={setDoneQuestions} />;
      }
      // return <Router pwaPrompt={pwaInstallEvent} />;
      return <Router firstTime={firstTime} />;
    } else {
      if (inStudy === 'yes') {
        return <StudySignup setLoggedIn={setLoggedIn} />;
      } else {
        return <Login setLoggedIn={setLoggedIn} setFirstTime={setFirstTime} />;
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback=''>{renderLogin()}</Suspense>
    </ThemeProvider>
  );
}

export default App;
